<template>
	<div class="h-100 w-100">
		<error-dialog />

		<layout-full>
			<router-view />
		</layout-full>
	</div>
</template>

<script>
	import LayoutFull from "@core/layouts/layout-full/LayoutFull.vue";
	import ErrorDialog from "@common-components/error-dialog/ErrorDialog.vue";

	export default {
		components: {
			LayoutFull,
			ErrorDialog,
		},
	};
</script>
